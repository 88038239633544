import * as React from 'react';

const ClientSideOnlyLazy = React.lazy(() => import('../sections/Main'));

const IndexPage = () => {
  return (
    <React.Suspense fallback={''}>
      <ClientSideOnlyLazy />
    </React.Suspense>
  );
};

export default IndexPage;
